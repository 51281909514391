<template>
  <svg
    width="14"
    height="16"
    viewBox="0 0 14 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M3 9C3 8.44687 3.44688 8 4 8C4.55312 8 5 8.44687 5 9C5 9.55313 4.55312 10 4 10C3.44688 10 3 9.55313 3 9ZM10 11C10.5531 11 11 11.4469 11 12C11 12.5531 10.5531 13 10 13C9.44687 13 9 12.5531 9 12C9 11.4469 9.44687 11 10 11ZM6 9C6 8.58438 6.33437 8.25 6.75 8.25H10.25C10.6656 8.25 11 8.58438 11 9C11 9.41562 10.6656 9.75 10.25 9.75H6.75C6.33437 9.75 6 9.41562 6 9ZM7.25 11.25C7.66563 11.25 8 11.5844 8 12C8 12.4156 7.66563 12.75 7.25 12.75H3.75C3.33437 12.75 3 12.4156 3 12C3 11.5844 3.33437 11.25 3.75 11.25H7.25ZM4.75 2H9.25V0.75C9.25 0.335938 9.58438 0 10 0C10.4156 0 10.75 0.335938 10.75 0.75V2H12C13.1031 2 14 2.89531 14 4V14C14 15.1031 13.1031 16 12 16H2C0.895313 16 0 15.1031 0 14V4C0 2.89531 0.895313 2 2 2H3.25V0.75C3.25 0.335938 3.58437 0 4 0C4.41563 0 4.75 0.335938 4.75 0.75V2ZM1.5 14C1.5 14.275 1.72375 14.5 2 14.5H12C12.275 14.5 12.5 14.275 12.5 14V6H1.5V14Z" />
  </svg>
</template>
